import React from 'react'
import styles from "../Tecnologia.module.css";
import IMG from "./img/satelites-spacex.png"

export const SpaceX = () => {
  return (
        <section className={styles.containerPost}>
            <div className={styles.boxPost}>
            <h2>Pesquisa indica que os satélites de Elon Musk estão 'impedindo' nossa observação do Universo.</h2>
                <span>19/09/2024</span>
                <img src={IMG} alt='SpaceX'/>
                <div className={styles.boxText}>
                    <p>
                        Um novo estudo revela que a nova geração de satélites Starlink,
                        que oferece internet de alta velocidade globalmente,
                        está causando sérias interferências nos radiotelescópios.
                    </p>

                    <p>
                        As ondas de rádio da crescente rede de satélites Starlink,
                         criada pelo bilionário Elon Musk, estão dificultando a observação
                          do Universo, segundo astrônomos na Holanda.
                    </p>

                    <p>
                        A nova geração dos satélites Starlink, que oferecem internet
                         rápida globalmente, está interferindo ainda mais nos
                          radiotelescópios do que as versões anteriores, afirmam
                          os cientistas.
                    </p>
                    <p>
                        Com milhares de satélites em órbita, esses 
                        dispositivos estão "cegando" radiotelescópios e
                         podem estar comprometendo pesquisas astronômicas,
                          de acordo com o Instituto Holandês de Radioastronomia (Astron).
                    </p>

                    <p>
                        A Starlink é uma divisão da SpaceX, a empresa de
                         exploração espacial de Elon Musk, que também é 
                         proprietário da rede social X.
                    </p>

                    <p>
                         A SpaceX não respondeu a um pedido de comentário da BBC News.
                    </p>

                    <p>
                        Os satélites oferecem acesso à internet
                         de banda larga em todo o mundo, principalmente em 
                         áreas remotas, incluindo locais de difícil acesso para a 
                         instalação de redes de telecomunicações, como na Ucrânia, 
                         no Iémen e na Amazônia.
                    </p>

                    <p>
                         Em 2022, testes mostraram que a Starlink 
                         poderia proporcionar velocidades de internet 
                         quatro vezes superiores à média, segundo o 
                         Departamento de Digital, Cultura, Mídia e Esporte.
                    </p>

                    <p>
                          Entretanto, os astrônomos alertam 
                          que essa inovação vem com um preço. 
                    </p>

                    <p>
                       Estamos tentando estudar fenômenos como os jatos emitidos 
                       por buracos negros no centro das galáxias. Também observamos
                       algumas das galáxias mais antigas, a milhões de anos-luz de distância,
                       além de exoplanetas", afirmou ela, ressaltando as áreas impactadas
                       pela radiação dos satélites.
                    </p>

                    <p>
                        De acordo com o Astron, a interferência provocada pelos satélites
                         de segunda geração é 32 vezes maior do que a causada pelos modelos anteriores.
                    </p>
                </div>

                
            </div>
    </section>
  )
}
