import React, { useEffect } from "react";
import styles from "./Game.module.css";
import Usuario from "./Usuario/Usuario";
import MemoryKunin from "./MemoryKunin/MemoryKunin";
import FishGame from "./FishGame/FishGame";
import RotatingImage from "../../RotatingImage/RotatingImage";
import ImgLogo from "../../icons/verso.jpg"




function Game() {



  return (
    <div>
        <div className="Game">
          {/* <Usuario/> */}
          <main className={styles.GameBody}>
           {/* <MemoryKunin/> */}
           {/* <FishGame/> */}
           <RotatingImage Image={ImgLogo}/>
           <h1 className={styles.GanutencaoGame}>Em Manutenção</h1>
          </main>
        </div>
    </div>
  );
}

export default Game;
