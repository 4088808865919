import React from 'react'
import styles from "../Tecnologia.module.css";
import IMG from "./img/fragata-liberal-barco.jpg"

export const Starlink = () => {
  return (
        <section className={styles.containerPost}>
            <div className={styles.boxPost}>
            <h2>Petrobras, forças armadas e embarcações: qual é a dependência do Brasil em relação à Starlink de Elon Musk?</h2>
                <span>12/09/2024</span>
                <img src={IMG} alt='SpaceX'/>
                <div className={styles.boxText}>
                    <p>
                    Em menos de dois anos, a Starlink se 
                    estabeleceu como líder em internet via satélite,
                     oferecendo serviços para forças armadas e grandes
                      empresas do setor petrolífero, como a Petrobras.
                    </p>

                    <p>
                    A recente controvérsia entre o Supremo Tribunal Federal
                     (STF) e Elon Musk, proprietário do X (antigo Twitter),
                      atraiu a atenção global na última semana devido à suspensão
                       da rede social no Brasil, que começou na semana passada.
                    </p>

                    <p>
                    O conflito entre Musk e o ministro Alexandre de Moraes
                     teve repercussões em outro empreendimento do bilionário
                      sul-africano: o serviço de internet via satélite Starlink.
                    </p>

                    <p>
                    No dia 30 de agosto, Moraes ordenou o bloqueio do X 
                    no Brasil devido ao não cumprimento de diversas determinações 
                    judiciais, incluindo a exigência de indicar um representante legal no país.
                    </p>

                    <p>
                    Musk reagiu, chamando Moraes de um "falso juiz" 
                    e acusando-o de ter promovido "interferência eleitoral 
                    em 2022" durante sua presidência no Tribunal Superior Eleitoral (TSE).
                    </p>

                    <p>
                    Poucos dias após a decisão, surgiram rumores no país sobre a
                     possibilidade de Moraes também suspender as atividades da Starlink.
                      Esse temor surgiu porque a empresa ainda não havia cumprido as ordens
                       de Moraes para bloquear o acesso ao X através de suas conexões via satélite.
                    </p>











                    <p>
                    Entretanto, na terça-feira (3/9), a Starlink anunciou 
                    que estava atendendo às determinações de Moraes, dissipando
                     a preocupação sobre a suspensão do serviço de internet da empresa.
                    </p>

                    <p>
                    No entanto, o simples receio de que a Starlink pudesse
                     ser desligada no Brasil gerou apreensão entre clientes 
                     e especialistas.
                    </p>

                    <p>
                    Em pouco mais de dois anos, a Starlink se consolidou
                     como líder em um nicho pequeno, mas estratégico, no setor 
                     de telecomunicações do país: a internet via satélite.
                    </p>

                    <p>
                    Durante esse período, a empresa se tornou fornecedora de 
                    importantes órgãos do governo federal, como o Exército, a Marinha,
                    e os ministérios da Saúde e Educação, além da gigante Petrobras.
                    </p>

                    <p>
                    Diante da relevância da Starlink para essas instituições, a BBC News
                     Brasil analisou documentos e consultou especialistas para responder
                      à pergunta: qual é a dependência do Brasil em relação à Starlink de Elon Musk?
                    </p>
                 
                     <h3>Qual a diferença da Starlink?</h3>
                     <br/>
                     <br/>
                     <p>
                        A Starlink é um braço da SpaceX, a companhia de exploração espacial de Elon Musk.
                     </p>

                     <p>
                        A empresa fornece serviços de internet por meio de uma enorme rede de satélites.
                         Ela é voltada para pessoas que vivem em áreas remotas, onde não há infraestrutura local como cabos e postes — caso de boa parte da Amazônia.
                     </p>

                      <p>
                      Estima-se que mais de 6 mil satélites Starlink já foram lançados no espaço,
                       segundo especialistas que monitoram esses equipamentos.
                      </p>
                      <p>
                      Segundo a própria empresa, trata-se da maior constelação de satélites do mundo,
                       com uma base de usuários em 37 países.
                      </p>














                       <p>
                       Rodrigo Hendges, gerente de segurança ofensiva da empresa de segurança de 
                       dados Max Protection, explica que a chave do sucesso da empresa é o fato de ela
                        usar, como nenhuma outra até então, um sistema uma rede de satélites de órbita
                         baixa capaz de fornecer uma conexão de baixa latência.
                        <br/>
                        <br/>
                        Latência é o tempo que leva para um dado (como uma mensagem ou um comando)
                         viajar de um ponto a outro na rede via satélite, ser processado e retornar
                          ao usuário.
                          <br/>
                        <br/>
                        Hendges explicou que a transmissão de sinais de internet via satélite não 
                        é uma tecnologia nova no mundo.
                        <br/>
                        <br/>
                        A diferença introduzida pela Starlink, segundo ele, é que enquanto 
                        a maior parte dos sistemas disponíveis no mercado usa satélites
                         localizados a pelo menos 30 mil quilômetros de altitude, os da
                          empresa de Musk estão a 500 quilômetros.
                          <br/>
                        <br/>
                        Com satélites muito mais perto da superfície terrestre
                         que seus concorrentes, o tempo de latência é menor. Na prática,
                          isso resulta em uma conexão mais estável e muito mais rápida.
                          <br/>
                        <br/>
                        Hendges ressalta ainda outra característica da Starlink
                         que a diferencia das demais competidoras: a rede de satélites
                          da Starlink é composta por milhares de satélites interconectados,
                           criando uma constelação que cobre grandes áreas.
                       </p>

                </div>

                
            </div>
    </section>
  )
}
