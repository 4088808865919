import { React, Image } from "react";
import styles from "./Home.module.css";
import BannerHome from "./BannerHome/BannerHome";
import Typical from "react-typical";
import { Link } from "react-router-dom";
import Ferramentas from "./Ferramentas/Ferramentas";
import BlogHome from "./Blogs/BlogHome/BlogHome";

const Home = () => {
  return (
    <section className={styles.homeContainer}>
      <div className={styles.boxIntroHome}>
          <Typical
            className={styles.h1}
            steps={[1000, "Fohattech"]}
            loop={Infinity}
            wrapper="h1"
          />
          <div className={styles.containerH2}>
            <h2>Fohattech é um blog que compartilha conteúdo sobre tecnologia, inovação e notícias de diversas categorias, além de oferecer uma variedade de produtos afiliados, ferramentas e jogos.</h2>
          </div>
      </div>
      
      <div className={styles.boxBlogHome}>
         <BlogHome/>
      </div>

      {/* <container className={styles.containerFD}>
        <p className={styles.textoFD}>Ferramentas disponíveis!</p>
        <div className={styles.cantainerSeta}>
          <p className={styles.seta}>☟</p>
        </div> */}
        {/* <di className={styles.containerIMC}> */}
        {/* <Ferramentas/> */}
          
          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}

          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3074013886457000"
     crossorigin="anonymous"></script>

          <ins class={styles.adsbygoogle}
              data-ad-client="ca-pub-3074013886457000"
              data-ad-slot="8941972203"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>

          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}



        {/* </di> */}
      {/* </container> */}
    </section>
  );
};

export default Home;
