import React from 'react';
import styles from "./BlogHome.module.css";
import { SpaceX } from '../Categorias/Tecnologia/SpaceX/SpaceX';
import { Starlink } from '../Categorias/Tecnologia/SpaceX/Starlink';
import Ferramentas from "../../Ferramentas/Ferramentas"



function BlogHome() {
  return (
    <section  className={styles.blogHomeContainer}>
        <div className={styles.postContainer}>

            <div className={styles.posts}>
                <SpaceX/>
            </div>
            <div className={styles.posts}>
               <Starlink/>
            </div>
           
        </div>
        <div className={styles.barraLateralContainer}>


            {/* <h3>Ferramentas disponíveis!</h3>
            <p className={styles.seta}>☟</p>
             <Ferramentas/> */}
        </div>

    </section>
  )
}

export default BlogHome;